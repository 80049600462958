<template>
  <div class="bodybox">
    <div class="Carousel">
      <swiper v-if="bannerList.length > 0" class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <a :href="item.url" target="_blank"><img :src="item.image" alt=""></a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="Reference">{{$t('discountsection')}}</div>
    <div class="music" v-loading="loading">
      <div v-for="(item, index) in DemoseaList" :key="index">
        <DemoSea :DemoseaList="item" type="isBuy"></DemoSea>
      </div>
	  <div class="emptyView">
	  	<el-empty v-if="isEmpty == true" :image-size="100"></el-empty>
	  </div>
    </div>
	<div class="more-wrap" v-if="isEmpty == false">
		<div v-if="(nothing == false) && (numMusician > pageSize)" class="more" @click="moreMusician">
			<Icon name="icon-7key-musicgengduoyinleren" theme-color="iconColorAll" width="16" height="16"></Icon>
			<div class="more-text">{{$t('moreMusic')}}</div>
		</div>
		<div class="nothing-text" v-if="(nothing == true) || ((numMusician < pageSize) && (numMusician > 0))">{{$t('nomore')}}</div>
	</div>
  </div>
</template>

<script>
import DemoSea from "@/components/content/DemoSea";
import { getIndexBanner, getCase, getMusicList } from "@/api/Home";

export default {
  props: {},
  data() {
    return {
		loading: true,
		isEmpty: false,
      code: "",
      DemoseaList: [],
      bannerList: [],
	  page: 1,
	  pageSize: 4,
	  nothing: false,
	  numMusician: '',
    };
  },
  components: {
    DemoSea,
  },
  computed: {},
  created() {
    this.code = this.$route.query.code;
    // this.getCaseList(); // getCaseList列表
    this.discountsList();
    this.getBanner();
  },
  mounted() { },
  watch: {
  	page: {
  		handler(newValue, oldValue) {
  	
  			console.log('page', newValue)
  			if (this.page == Math.ceil(this.numMusician / this.pageSize)) {
  				setTimeout(() => {
  					this.nothing = true
  				}, 1000)
  	
  			}
  			// this.themeName = newValue
  			// console.log(this.GlobalCSS[newValue])
  			// this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
  		}
  	},
  },
  methods: {
	  moreMusician(){
	  	// if(Math.ceil(this.numMusician / this.pageSize))
	  	// console.log(Math.ceil(this.numMusician / this.pageSize))
	  	console.log(this.page,Math.ceil(this.numMusician / this.pageSize),',,,,,,,,,,')
	  	if(this.page < Math.ceil(this.numMusician / this.pageSize)){
	  		this.page = this.page + 1
	  		this.discountsList()
	  	}
	  	
	  },
    //折扣专区列表
    discountsList() {
      getMusicList({
        is_discount: 1,//0正常，1折扣
        page: this.page, //页数
        pageSize: this.pageSize,
        min_bmp: "", //最小bmp
        max_bmp: "", //最大bmp
        bmp: "", //节拍(精准)
        key: "", //曲调id
        instruments: "", //乐器id
        genres: "", //风格id
        title: "", //搜索标题
        type: "", //搜索方式:1:歌曲名称搜索;2:制作人搜索; title 为搜索内容
        recommend: 1, //1海外 2内地
      })
        .then((res) => {
			this.numMusician = res.data.count
			this.loading = false;
			if(this.page == 1){
				this.DemoseaList = res.data.list
			}else {
				this.DemoseaList = this.DemoseaList.concat(res.data.list)
			}
			if(this.DemoseaList == ''){
				this.isEmpty = true
			}else {
				this.isEmpty = false
			}
          // this.DemoseaList = res.data.list
          console.log(res, "折扣列表成功");
        })
        .catch((err) => {
          console.log(err, "折扣列表失败");
        });
    },
    // 获取banner
    getBanner() {
      getIndexBanner()
        .then((res) => {
          console.log(res, '获取banner成功');
          this.bannerList = res.data.list
        })
        .catch((err) => {
          // console.log(err, '获取banner失败');
        });
    },
    // getCaseList列表
    getCaseList() {
      getCase({
        code: "REF011",
      })
        .then((res) => {
          console.log(this.code, " this.code this.code this.code this.code");
          console.log(res, "列表成功");
        })
        .catch((err) => {
          console.log(err, "列表失败");
        });
    },
  },
};
</script>

<style scoped lang="less">
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
.bodybox {
  width: 100%;
  height: auto;

  .Carousel {
    width: 100%;
    height: 350px;
    margin-top: 24px;

    img {
      width: 100%;
      height: 350px;
    }
  }

  .Reference {
    width: 117px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
    margin-top: 20px;
  }

  .music {
    width: 100%;
    // height: 500px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 20px;
	.emptyView{
		width: 100%;
		display: flex;
		justify-content: center;
	}
  }
  .more-wrap {
  	display: flex;
  	width: 100%;
  	justify-content: center;
  	margin-top: 20px;
  
  	.more {
  		width: 190px;
  		height: 36px;
  		border-radius: 6px;
  		border: 1px solid @recommendMusicianListTextColor;
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		cursor: pointer;
  
  		.more-text {
  			font-size: 14px;
  			font-family: PingFangSC-Medium, PingFang SC;
  			font-weight: 500;
  			color: @recommendMusicianListTextColor;
  			line-height: 20px;
  			margin-left: 10px;
  		}
  	}
	.nothing-text{
		color: @recommendMusicianListTitleColor;
	}
  }
}
</style>
